/**
 * Message input char counter
 */
const MAX_CHAR_COUNT = 200;

/** @type {HTMLInputElement} */
const messageInputEl = document.getElementById('message-input');
const messageCharCountEl = document.getElementById('message-char-count');

/**
 *
 * @param {Event} event
 */
function updateCharCount(event) {
    /** @type {number} */
    const messsageLength = event.target.value.length;

    messageCharCountEl.innerText = `${messsageLength}/${MAX_CHAR_COUNT}`;
    messageCharCountEl.classList.toggle('limit-reached', messsageLength === MAX_CHAR_COUNT);
}

messageInputEl.addEventListener('input', updateCharCount);

messageInputEl.setAttribute('maxlength', MAX_CHAR_COUNT);
messageCharCountEl.innerText = `${messageInputEl.value.length}/${MAX_CHAR_COUNT}`;
