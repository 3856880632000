import { transfer } from '../lib/transfer';

/** @type {HTMLFormElement} */
const form = document.getElementById('form');
const submitButtonEl = document.getElementById('submit-button');

form.addEventListener('submit', (e) => {
    e.preventDefault();
    submitButtonEl.setAttribute('disabled', '');

    const formData = new FormData(document.querySelector('form'));

    const amount = Number(formData.get('amount'));
    /** @type {string | null} */
    const message = formData.get('message');

    if (amount && !Number.isNaN(amount)) {
        transfer(amount, message);
    }

    submitButtonEl.removeAttribute('disabled');
    return false;
});
