const DEEP_LINK_TPL = 'ton://transfer/{to}?amount={amount}&text={text}';

/**
 * @param {number} amount
 * @param {string} message
 */
export function transfer(amount, message) {
    const tonAddress = document.querySelector('h2.domain').innerText;

    if (process.env.NODE_ENV === 'development' || process.env.DEMO_MODE === 'true') {
        console.log('[DEBUG] Sending data', {
            address: tonAddress,
            amount,
            message,
        });
    }

    if (!tonAddress) {
        return;
    }

    location.href = DEEP_LINK_TPL.replace('{to}', tonAddress)
        .replace('{amount}', String(amount * 1e9))
        .replace('{text}', message || '');
}
