/**
 * Card flip function
 */
const flipButtonEl = document.getElementById('flip-button');
const cardWrapperEl = document.getElementById('card-wrapper');
const cardInputEl = document.getElementById('card-input');
const copyButtonEl = document.getElementById('copy-button');
const shareButtonEl = document.getElementById('share-button');
const copyTooltipEl = document.getElementById('copy-tooltip');

const TOOLTIP_HIDE_DELAY_MS = 3000; // 3s

function flipCard() {
    cardWrapperEl.classList.toggle('flipped');
}

/**
 * @param {MouseEvent} event
 */
function handleInputClick(event) {
    const input = event.currentTarget;
    if (input) {
        input.setSelectionRange(0, input.value.length);
    }
}

function handleCopyButtonClick() {
    window.navigator.clipboard.writeText(cardInputEl.value);
    copyTooltipEl.textContent = 'Wallet address copied!';
    copyTooltipEl.classList.add('!opacity-100');

    cardInputEl.focus();
    cardInputEl.setSelectionRange(0, 0);
    cardInputEl.blur();

    setTimeout(() => {
        copyTooltipEl.classList.remove('!opacity-100');
    }, TOOLTIP_HIDE_DELAY_MS);
}

function handleShareButtonClick() {
    let url = document.location.host;

    const formData = new FormData(document.querySelector('form'));

    const amount = Number(formData.get('amount'));
    /** @type {string | null} */
    const message = formData.get('message');

    if (amount && !Number.isNaN(amount)) {
        url += `/${amount}`;
    }
    if (message) {
        url += `/${encodeURIComponent(message)}`;
    }

    window.navigator.clipboard.writeText(url);
    copyTooltipEl.textContent = 'Link copied!';
    copyTooltipEl.classList.add('!opacity-100');

    setTimeout(() => {
        copyTooltipEl.classList.remove('!opacity-100');
    }, TOOLTIP_HIDE_DELAY_MS);
}

// flipButtonEl.addEventListener('click', flipCard);
// copyButtonEl.addEventListener('click', handleCopyButtonClick);
// shareButtonEl.addEventListener('click', handleShareButtonClick);
// cardInputEl.addEventListener('click', handleInputClick);
