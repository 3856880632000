import { ajdustTextSize } from '../lib/textAdjust';

/**
 * Amount input controls
 */
const MIN_AMOUNT = 0;
const MAX_AMOUNT = 999999; // This is mostly to fit the number in the UI
const MAX_DECIMAL_POINTS = 4;
const TON_PRICE_API_URL = 'https://api.tonnames.org/ton-price';
const CONVERSION_RATE_POLL_INTERVAL_MS = 60 * 1000; // 1 min
/** @type {number | undefined} */
let TOKEN_TO_USD_CONVERTION_RATE = undefined;

/** @type {HTMLInputElement} */
const amountInputEl = document.getElementById('amount-input');
const plusButtonEl = document.getElementById('plus-button');
const minusButtonEl = document.getElementById('minus-button');
const submitButtonEl = document.getElementById('submit-button');
const convertedAmountEl = document.getElementById('converted-amount');

function fireInputEvent() {
    amountInputEl.dispatchEvent(new Event('input'));
}

function incrementInput() {
    amountInputEl.value = Math.floor(Number(amountInputEl.value)) + 1;
    fireInputEvent();
}

function decrementInput() {
    amountInputEl.value = Math.ceil(Number(amountInputEl.value)) - 1;
    fireInputEvent();
}

function setupInput() {
    amountInputEl.setAttribute('min', MIN_AMOUNT);
    if (MAX_AMOUNT !== Infinity) {
        amountInputEl.setAttribute('max', MAX_AMOUNT);
    }
}

async function getConversionRate() {
    try {
        const response = await fetch(TON_PRICE_API_URL);
        const { price } = await response.json();

        if (!TOKEN_TO_USD_CONVERTION_RATE) {
            convertedAmountEl.classList.add('visible');
        } else if (price !== TOKEN_TO_USD_CONVERTION_RATE) {
            convertedAmountEl.classList.add('flash');

            setTimeout(() => {
                convertedAmountEl.classList.remove('flash');
            }, 1000);
        }

        TOKEN_TO_USD_CONVERTION_RATE = price;
        checkInput();
    } catch (err) {
        console.error(err);
    }
}

function pollConversionRate() {
    getConversionRate();

    setTimeout(() => pollConversionRate(), CONVERSION_RATE_POLL_INTERVAL_MS);
}

/**
 * @param {number} amount
 */
function updateConvertedValue(amount) {
    if (!amount || !TOKEN_TO_USD_CONVERTION_RATE) {
        convertedAmountEl.textContent = '';
        return;
    }

    const newAmount = (amount * TOKEN_TO_USD_CONVERTION_RATE)
        .toFixed(2)
        .toString()
        .replace('.', ',');

    convertedAmountEl.textContent = `~$${newAmount}`;
    ajdustTextSize(convertedAmountEl);
}

/**
 * Replaces commas with dots for the number input to function properly
 *
 * @param {KeyboardEvent} event
 */
function alignInputValue(event) {
    if (event.key === ',') {
        event.preventDefault();
        amountInputEl.value += '.';
    }
}

function checkInput() {
    let amount = Number(amountInputEl.value);

    minusButtonEl.removeAttribute('disabled');
    plusButtonEl.removeAttribute('disabled');

    if (amount.toString() !== amountInputEl.value) {
        amountInputEl.value = amount;
    }

    if (Number.isNaN(amount) || amount <= MIN_AMOUNT) {
        amountInputEl.value = MIN_AMOUNT;
        minusButtonEl.setAttribute('disabled', '');
    } else if (amount >= MAX_AMOUNT) {
        amountInputEl.value = MAX_AMOUNT;
        plusButtonEl.setAttribute('disabled', '');
    } else if (Math.floor(amount) !== amount) {
        const decimalPointsAmount = amount.toString().split('.')[1].length || 0;
        if (decimalPointsAmount > MAX_DECIMAL_POINTS) {
            amountInputEl.value = amount.toFixed(MAX_DECIMAL_POINTS);
        }
    }

    // Re-evaluate value of the input, since it could be changed on previous lines
    amount = Number(amountInputEl.value);
    updateConvertedValue(amount);

    if (amount === 0) {
        submitButtonEl.setAttribute('disabled', '');
    } else {
        submitButtonEl.removeAttribute('disabled');
    }
}

plusButtonEl.addEventListener('click', incrementInput);
minusButtonEl.addEventListener('click', decrementInput);
amountInputEl.addEventListener('keydown', alignInputValue);
amountInputEl.addEventListener('input', checkInput);

window.addEventListener('DOMContentLoaded', () => {
    setupInput();
    checkInput();
    pollConversionRate();
});
