/**
 * @param {HTMLElement} element
 */
export function ajdustTextSize(element) {
    const textWidth = element.scrollWidth;
    const elementWidth = element.offsetWidth;

    if (textWidth > elementWidth) {
        const computedStyle = window.getComputedStyle(element);

        element.style.fontSize = `calc(${computedStyle.fontSize} * ${elementWidth / textWidth})`;
    } else {
        element.style.fontSize = '';
    }
}
