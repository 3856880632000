/**
 * Mobile navigation
 */
const navigationEl = document.getElementById('mobile-navigation');
const navigationOpenEl = document.getElementById('mobile-nav-open');
const navigationCloseEl = document.getElementById('mobile-nav-close');

function openMenu() {
    navigationEl.classList.remove('opacity-0', 'pointer-events-none');
}

function closeMenu() {
    navigationEl.classList.add('opacity-0', 'pointer-events-none');
}

navigationOpenEl.addEventListener('click', openMenu);
navigationCloseEl.addEventListener('click', closeMenu);
